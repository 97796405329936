<template>
<div class="bg--valentine" :class="{'bg--select' : !format}">
  <header-block cls="header__menu header__menu--valentine"/>
  <div class="container container--valentine">
    <div class="valentine">
      <div class="valentine-choice__block">
        <div class="valentine-choice__top">
          <button class="valentine-choice__change" @click.prevent="selectFormat('')">
            Изменить формат "{{ title }}"
          </button>
        </div>
        <div class="valentine-choice">
          <div class="valentine-choice__left">
            <button class="valentine-choice__button"
              @click.prevent="selectTab('template')"
              :class="{'valentine-choice__button--active' : tab === 'template'}"
            >
              <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M13 11H18C19.1046 11 20 11.8954 20 13V18C20 19.1046
                  19.1046 20 18 20H13C11.8954 20 11 19.1046 11 18V13C11 11.8954
                  11.8954 11 13 11ZM13 13V18H18V13H13ZM9 2V18C9 19.1046 8.10457 20
                  7 20H2C0.89543 20 0 19.1046 0 18V2C0 0.89543 0.89543 0 2 0H7C8.10457
                  0 9 0.89543 9 2ZM7 2H2V18H7V2ZM13 0H18C19.1046 0 20 0.89543 20
                  2V7C20 8.10457 19.1046 9 18 9H13C11.8954 9 11 8.10457 11 7V2C11 0.89543
                  11.8954 0 13 0ZM13 2V7H18V2H13Z" fill="white"
                />
              </svg>
              <span class="valentine-choice__button-txt">
                Рамки
              </span>
            </button>
            <button class="valentine-choice__button"
              @click.prevent="selectTab('photo')"
              :class="{'valentine-choice__button--active' : tab === 'photo'}"
            >
              <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <!-- eslint-disable-next-line -->
                <path d="M6.71 5.71002L9 3.41002V13C9 13.2652 9.10536 13.5196 9.29289 13.7071C9.48043 13.8947 9.73478 14 10 14C10.2652 14 10.5196 13.8947 10.7071 13.7071C10.8946 13.5196 11 13.2652 11 13V3.41002L13.29 5.71002C13.383 5.80375 13.4936 5.87814 13.6154 5.92891C13.7373 5.97968 13.868 6.00582 14 6.00582C14.132 6.00582 14.2627 5.97968 14.3846 5.92891C14.5064 5.87814 14.617 5.80375 14.71 5.71002C14.8037 5.61706 14.8781 5.50645 14.9289 5.3846C14.9797 5.26274 15.0058 5.13203 15.0058 5.00002C15.0058 4.86801 14.9797 4.7373 14.9289 4.61544C14.8781 4.49358 14.8037 4.38298 14.71 4.29002L10.71 0.290018C10.6149 0.198978 10.5028 0.127613 10.38 0.0800184C10.1365 -0.0199996 9.86346 -0.0199996 9.62 0.0800184C9.49725 0.127613 9.3851 0.198978 9.29 0.290018L5.29 4.29002C5.19676 4.38326 5.1228 4.49395 5.07234 4.61577C5.02188 4.73759 4.99591 4.86816 4.99591 5.00002C4.99591 5.13188 5.02188 5.26245 5.07234 5.38427C5.1228 5.50609 5.19676 5.61678 5.29 5.71002C5.38324 5.80326 5.49393 5.87722 5.61575 5.92768C5.73757 5.97814 5.86814 6.00411 6 6.00411C6.13186 6.00411 6.26243 5.97814 6.38425 5.92768C6.50607 5.87722 6.61676 5.80326 6.71 5.71002ZM19 12C18.7348 12 18.4804 12.1054 18.2929 12.2929C18.1054 12.4804 18 12.7348 18 13V17C18 17.2652 17.8946 17.5196 17.7071 17.7071C17.5196 17.8947 17.2652 18 17 18H3C2.73478 18 2.48043 17.8947 2.29289 17.7071C2.10536 17.5196 2 17.2652 2 17V13C2 12.7348 1.89464 12.4804 1.70711 12.2929C1.51957 12.1054 1.26522 12 1 12C0.734784 12 0.48043 12.1054 0.292893 12.2929C0.105357 12.4804 0 12.7348 0 13V17C0 17.7957 0.316071 18.5587 0.87868 19.1213C1.44129 19.6839 2.20435 20 3 20H17C17.7956 20 18.5587 19.6839 19.1213 19.1213C19.6839 18.5587 20 17.7957 20 17V13C20 12.7348 19.8946 12.4804 19.7071 12.2929C19.5196 12.1054 19.2652 12 19 12Z" fill="white"/>
              </svg>
              <span class="valentine-choice__button-txt">
                Загрузить фото
              </span>
            </button>
          </div>
          <div class="valentine-choice__right"
            v-show="tab || !isMobile"
          >
            <div class="valentine-choice__template-block"
              :class="{'active' : tab === 'template'}"
              v-if="tab === 'template'"
            >
              <p class="valentine-choice__desc">
                Выберите рамку
              </p>
              <div class="valentine-choice__templates">
                <div
                  class="valentine-choice__template"
                  v-for="n in count"
                  :key="n"
                  @click.prevent="selectImg(imgUrl + n + '.png')"
                >
                  <img :src="imgUrl + n + '.png'"
                    alt="img"
                    class="valentine-choice__template-img"
                  >
                  <p class="valentine-choice__template-name">
                    Шаблон {{ n }}
                  </p>
                </div>
              </div>
            </div>
            <div class="valentine-choice__downloads"
              :class="{'active' : tab === 'photo'}"
              v-if="tab === 'photo'"
            >
              <p class="valentine-choice__desc">
                Загрузите изображение, чтобы начать
              </p>
              <div class="valentine-choice__download">
                <label for="download" class="valentine-choice__download-label">
                  <span class="valentine-choice__download-txt">Загрузить</span>
                  <input id="download" type="file" class="valentine-choice__download-input"
                    ref="imgInput"
                    @change="changeUserImg"
                  >
                </label>
              </div>
              <div class="valentine-choice__download-img_block">
                <img :src="userImg"
                  v-show="userImg"
                  alt="img" class="valentine-choice__download-img"
                />
                <button v-if="userImg"
                  class="valentine-choice__download-delete" @click.prevent="deleteImage"
                />
              </div>
            </div>
            <button class="valentine-choice__close" @click.prevent="tab = ''"></button>
          </div>
        </div>
      </div>
      <div class="valentine-sheet__block">
        <div class="valentine-sheet__info">
        </div>
        <div class="valentine-sheet" :class="'valentine-sheet--' + format">
          <img :src="img" alt="" class="valentine-sheet__img" v-if="img" crossorigin="anonymous">
          <div id="canvas-container">
            <canvas id="c" crossorigin="anonymous" />
          </div>

        </div>
      </div>
      <div class="valentine__download-block">
        <button class="valentine-choice__change" @click.prevent="selectFormat('')">
          Изменить формат "{{ title }}"
        </button>
        <button class="valentine__download" @click.prevent="saveImg">
          Скачать
        </button>
      </div>
    </div>
  </div>
  <div class="popup-format__block" v-if="!format">
    <div class="popup-format">
      <p class="popup-format__tit">
        Выберите формат и создайте дизайн
      </p>
      <div class="popup-format__designs">
        <div class="popup-format__design popup-format__design--1"
          @click.prevent="selectFormat('square')"
        >
          <div class="popup-format__design-img_block">
            <img src="@/assets/images/design.png" srcset="@/assets/images/design@2x.png 2x"
              alt="img" class="popup-format__design-img">
          </div>
          <p class="popup-format__design-name">
            Квадрат
          </p>
        </div>
        <div class="popup-format__design popup-format__design--2"
          @click.prevent="selectFormat('postcard')"
        >
          <div class="popup-format__design-img_block">
            <img src="@/assets/images/design_2.png" srcset="@/assets/images/design_2@2x.png 2x"
              alt="img" class="popup-format__design-img">
          </div>
          <p class="popup-format__design-name">
            Открытка
          </p>
        </div>
        <div class="popup-format__design popup-format__design--3"
          @click.prevent="selectFormat('stories')"
        >
          <div class="popup-format__design-img_block">
            <img src="@/assets/images/design_3.png" srcset="@/assets/images/design_3@2x.png 2x"
              alt="img" class="popup-format__design-img">
          </div>
          <p class="popup-format__design-name">
            Сториз
          </p>
        </div>
      </div>
    </div>
  </div>
  <a href="" ref="link" download="image.jpg"></a>
</div>
</template>

<script>
import { fabric } from 'fabric';
import HeaderBlock from '@/components/Header.vue';
import auth from '@/mixins/auth';

export default {
  mixins: [auth],
  components: {
    HeaderBlock,
  },
  data() {
    return {
      formats: {
        square: {
          title: 'Квадрат',
        },
        postcard: {
          title: 'Открытка',
        },
        stories: {
          title: 'Сториз',
        },
      },
      format: null,
      tab: 'template',
      img: null,
      isMobile: false,
      cropper: null,
      userImg: null,
      width: 0,
      height: 0,
      left: 0,
      top: 0,
      canvas: null,
    };
  },
  name: 'Valentine',
  created() {
    const format = localStorage.getItem('format');
    if (format) {
      this.selectFormat(format);
    }
    const windowWidth = document.body.clientWidth;
    if (windowWidth <= 768) {
      this.isMobile = true;
      this.tab = '';
    }
  },
  mounted() {
    const { container } = this.$refs;
    if (container) {
      this.updateCoordinates(container.clientWidth - 6, container.clientHeight - 6, 0, 0);
    }
    this.fitResponsiveCanvas();
    window.onresize = () => this.fitResponsiveCanvas();
  },
  methods: {
    deleteImage() {
      this.userImg = null;
      this.canvas.dispose();
      this.canvas = null;
    },
    selectTab(tab) {
      this.tab = tab;
    },
    selectFormat(format) {
      this.format = format;
      localStorage.setItem('format', format);
    },
    selectImg(image) {
      this.img = image;
      if (this.isMobile) {
        this.tab = '';
      }
      if (this.userImg) {
        this.addImage();
      }
    },
    changeUserImg() {
      const [file] = this.$refs.imgInput.files;
      if (file) {
        this.userImg = URL.createObjectURL(file);
        if (this.isMobile) {
          this.tab = null;
        }
        this.$nextTick(() => {
          this.addImage();
        });
      }
    },
    addImage() {
      if (this.canvas) {
        this.canvas.dispose();
      }
      this.canvas = new fabric.Canvas('c');
      this.fitResponsiveCanvas();
      fabric.Object.prototype.transparentCorners = false;
      fabric.Object.prototype.cornerColor = '#1EB8D2';
      fabric.Object.prototype.cornerStyle = 'circle';

      fabric.Image.fromURL(this.userImg, (img) => {
        img.scaleToWidth(this.canvas.getWidth() - 90);
        this.canvas.add(img).setActiveObject(img);
        this.canvas.centerObject(img);
      });

      this.canvas.setOverlayImage(this.img, () => {
        this.canvas.overlayImage.scaleToWidth(this.canvas.getWidth());
        this.canvas.controlsAboveOverlay = true;
        this.canvas.renderAll();
      }, {
        crossOrigin: 'anonymous',
      });
    },
    fitResponsiveCanvas() {
      if (!this.canvas) {
        return;
      }
      const containerSize = {
        width: document.getElementById('canvas-container').offsetWidth,
        height: document.getElementById('canvas-container').offsetHeight,
      };
      this.canvas.setWidth(containerSize.width);
      this.canvas.setHeight(containerSize.height);
    },
    saveImg() {
      if (!this.canvas) {
        alert('Загрузите ваше фото');
        return;
      }
      const multiplier = this.isMobile ? 4 : 2;
      const image = this.canvas.toDataURL({
        format: 'jpeg',
        multiplier,
      });
      this.$refs.link.href = image;
      this.$refs.link.click();
    },
  },
  computed: {
    title() {
      if (!this.format) {
        return '';
      }
      return this.formats[this.format].title;
    },
    imgUrl() {
      if (!this.format) {
        return '';
      }
      return `https://500405.selcdn.ru/lerfit/valentine/${this.format}`;
    },
    count() {
      const counts = {
        square: 17,
        postcard: 17,
        stories: 17,
      };

      return counts[this.format];
    },
  },
};
</script>

<style>
#canvas-container {
  height: 100%;
}
.bg--select {
  height: 100vh;
  overflow: auto;
}
@media (max-width: 768px) {
  .bg--select {
    overflow: hidden;
  }
}
.valentine-choice__template-block,
.valentine-choice__downloads {
  display: none;
}
.valentine-choice__template-block.active,
.valentine-choice__downloads.active {
  display: block;
}
</style>
